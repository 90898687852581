body{
  min-width: 320px;
  overflow-x: hidden;
  font-size: 1rem;
  color: $commonColor;
  font-family: $mainFont;
  background-image: url("../images/bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

h1, h2, h3, h4, h5, h6{
  color: $accentColor;
  font-weight: 400;
  margin: 0;
}

h1{
  font-size: 2rem;
}

p{
  margin: 0;
  line-height: 1.5;
}

button, input, optgroup, select, textarea{
  font-family: $mainFont;
}

::selection {
  color: $lightColor;
  background: $accentColor;
}

.wrapper{
  width: 1140px;
  max-width: 100%;
  margin: 100px auto;
  background-color: $lightColor;
}

.content{
  padding: 55px 80px 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.11);
}

.header{
  &-logo{
    padding: 64px 80px 32px;
  }
  &-img{
    width: 100%;
    height: 285px;
    background-color: $accentColor;
    background-image: url("../images/headerImg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.info{
  width: 645px;
  max-width: 100%;
  padding-bottom: 40px;
  &-heading{
    padding-bottom: 15px;
  }
  &-description{
    padding-bottom: 15px;
  }
  &-sign{
    color: $errorColor;
  }
}

label{
  color: $commonColor;
  display: inline-block;
  padding-bottom: 7px;
  font-size: 0.875rem;
}

.btn{
  width: 350px;
  height: 40px;
  margin: 60px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $successColor;
  color: #333333;
  font-size: 1rem;
  border: none;
  line-height: 1.5;
  max-width: 100%;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:focus{
    outline-color: $accentColor;
  }
  &:hover{
    background-color: darken($successColor, 2);
  }
}

.message-info{
  line-height: 1.5;
  font-size: 1rem;
  color: $commonColor;
  text-indent: 23px;
  background-color: #FAFAFA;
  padding: 15px;
  margin-bottom: 20px;
  width: 570px;
  max-width: 100%;
  span:first-child{
    position: relative;
  }
  span:first-child:before{
    content: '';
    display: inline-block;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0C3.58214 0 0 3.58214 0 8C0 12.4179 3.58214 16 8 16C12.4179 16 16 12.4179 16 8C16 3.58214 12.4179 0 8 0Z' fill='%2307676A'/%3E%3Cpath d='M7.14282 11.1429C7.14282 11.3702 7.23313 11.5882 7.39387 11.7489C7.55462 11.9097 7.77264 12 7.99997 12C8.22729 12 8.44531 11.9097 8.60606 11.7489C8.7668 11.5882 8.85711 11.3702 8.85711 11.1429C8.85711 10.9155 8.7668 10.6975 8.60606 10.5368C8.44531 10.376 8.22729 10.2857 7.99997 10.2857C7.77264 10.2857 7.55462 10.376 7.39387 10.5368C7.23313 10.6975 7.14282 10.9155 7.14282 11.1429V11.1429ZM7.57139 9.14286H8.42854C8.50711 9.14286 8.57139 9.07857 8.57139 9V4.14286C8.57139 4.06429 8.50711 4 8.42854 4H7.57139C7.49282 4 7.42854 4.06429 7.42854 4.14286V9C7.42854 9.07857 7.49282 9.14286 7.57139 9.14286Z' fill='white'/%3E%3C/svg%3E");
    width: 16px;
    height: 16px;
    position: absolute;
    top: 2px;
    left: -23px;
  }
  ul{
    padding: 0;
    margin: 20px 0;
    list-style: none;
    li{
      text-indent: 0;
      &:before{
        content: '- ';
      }
    }
  }
}
.message-info--small{
  font-size: 0.875rem;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 0;
  background: none;
  color: $commonColor;
  padding: 0;
  margin-left: 15px;
}
.message-success{
  font-size: 0.875rem;
  text-align: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0;
  background: none;
  color: $accentColor;
  padding: 0;
}
.required:after{
  content: ' *';
  color: $errorColor;
}
.link{
  color: $accentColor;
  text-decoration: none;
  &:hover, &:focus{
    color: $successColor;
  }
}

.message-error{
  color: $errorColor;
  font-size: 0.875rem;
  margin-top: 5px;
}

.input-error, .input-error .ant-select-selector{
  border-color: $errorColor !important;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.modal-window {
  width: 884px;
  max-width: 80%;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}
.modal-closeBtn {
  cursor: pointer;
  border: none;
  position: absolute;
  display: block;
  padding: 5px;
  right: 16px;
  top: 16px;
  font-size: 30px;
  line-height: 24px;
  transition: 0.2s;
  z-index: 100;
  background-size: contain;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6487 12L21.9397 2.40184C22.0787 2.24233 21.9618 2 21.7471 2H19.2266C19.0782 2 18.936 2.06442 18.8381 2.17485L12 10.092L5.16189 2.17485C5.06714 2.06442 4.92501 2 4.7734 2H2.25293C2.03815 2 1.92129 2.24233 2.06026 2.40184L10.3513 12L2.06026 21.5982C2.02913 21.6337 2.00916 21.6772 2.00272 21.7234C1.99628 21.7697 2.00363 21.8168 2.02392 21.8591C2.04421 21.9014 2.07657 21.9372 2.11716 21.9621C2.15775 21.9871 2.20488 22.0003 2.25293 22H4.7734C4.92185 22 5.06398 21.9356 5.16189 21.8251L12 13.908L18.8381 21.8251C18.9329 21.9356 19.075 22 19.2266 22H21.7471C21.9618 22 22.0787 21.7577 21.9397 21.5982L13.6487 12Z' fill='%23232018'/%3E%3C/svg%3E%0A");
}
.modal-closeBtn:focus {
  outline: none;
}
.modal-content{
  padding: 60px 130px 72px;
}
.modal-title{
  font-weight: 400;
  line-height: 1.25;
  text-align: center;
  padding-bottom: 35px;
  margin-bottom: 0;
}
.modal-description{
  padding: 0 30px;
  font-size: 1rem;
  color: $commonColor;
  p{
    margin-bottom: 20px;
  }
}
.modal-btnWrapper{
  text-align: center;
}
.modal-btn{
  width: 320px;
  max-width: 100%;
}
.modal-icon{
  text-align: center;
  padding-bottom: 35px;
}

.checkMark{
  width: 56px;
  height: 56px;
  display: inline-block;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkMark-bg{
  stroke-dasharray: 220;
  stroke-dashoffset: 220;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: $accentColor;
  fill: $accentColor;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkMark-item{
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-width: 5px;
  stroke: $lightColor;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--accent2-color);
  }
}

@media only screen
and (max-width: 1199px){
  .modal-window{
    top: 20px;
  }
}

@media only screen
and (max-width: 991px){
  .modal-content {
    padding: 50px;
  }
}

@media only screen
and (max-width: 767px){
  .modal-description{
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .modal-icon{
    padding-bottom: 15px;
  }
  .modal-content {
    padding: 40px 20px;
  }
  .modal-description{
    font-size: 0.875rem;
    p{
      margin-bottom: 10px;
    }
  }
  .modal-title{
    font-size: 1rem;
    padding-bottom: 20px;
  }
  .modal .btn{
    margin-top: 30px;
  }
}