.radioImage-input {
  position: absolute;
  left: -9999px;
}
.radioImages{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: -30px;
}
.radioImage{
  width: 15%;
  text-align: center;
  font-size: 0.875rem;
  color: var( --black-color);
  margin-bottom: 30px;
  &:nth-child(4){
    .radioImage-label img, .radioImage-label svg{
      padding: 30px;
    }
  }
}

.radioImage p{
  margin-bottom: 0;
  margin-top: 10px;
}
.radioImage-label{
  width: 100%;
  height: auto;
  display: block;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  padding: 0;
}
.radioImage-label:after{
  content: "";
  padding-top: 100%;
  display: block;
}
.radioImage-label img, .radioImage-label svg{
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  object-fit: cover;
  transition: 0.2s ease-in-out;
  border: 1px solid $borderColor;
  cursor: pointer;
}
.radioImage-input:checked + .radioImage-label>img, .radioImage-input:checked + .radioImage-label>svg{
  border: 2px solid $accentColor;
}
