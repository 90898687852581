@import "../../node_modules/normalize-scss/sass/normalize/import-now";
@import '~antd/dist/antd.css';
@import './variables';
@import './general';
@import './radioImage';

.content{
  .ant-radio-button-wrapper{
    border: 1px solid $accentColor;
    color: $secondaryColor;
  }
  .ant-radio-button-wrapper:hover{
    color: $accentColor;
  }
  .ant-radio-button-wrapper{
    margin-left: -1px;
    margin-top: 0;
  }
  .ant-radio-button-wrapper:first-child{
    margin-left: 0;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
  .ant-radio-button-wrapper:not(:first-child)::before{
    background-color: $accentColor;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
    background: $accentColor;
    border-color: $accentColor;
  }
  .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{
    border-right-color: $accentColor;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child{
    border-color: $accentColor;
  }
  .ant-input:hover{
    border-color: $accentColor;
  }
  .ant-input{
    padding: 6.5px 11px;
    font-size: 1rem;
  }
  .ant-input:focus, .ant-input-focused,
  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker:hover, .ant-picker-focused{
    border-color: $accentColor;
    box-shadow: none;
  }
  .ant-picker-input{
    flex-direction: row-reverse;
  }
  .ant-picker-suffix{
    margin-left: 0;
    margin-right: 5px;
    color: $secondaryColor;
  }
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon{
    color: $accentColor
  }
  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover{
    border-color: $accentColor;
  }
  .ant-checkbox-group-item{
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .ant-checkbox + span{
    font-size: 1rem;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
    border-color: $accentColor;
  }
  .ant-checkbox-checked .ant-checkbox-inner{
    background-color: $accentColor;
    border-color: $accentColor;
  }
  .ant-checkbox-group{
    label:last-child{
      margin-bottom: 0;
    }
  }
  .ant-upload-list-item-name{
    color: $successColor;
  }
  .ant-input-number-lg{
    width: 100%;
  }
}
.question{
  margin-bottom: 20px;
  &--radio{
    width: 570px;
    max-width: 100%;
    box-sizing: content-box;
  }
  &--radio3{
    width: 855px;
    max-width: 100%;
  }
  &--upload{
    width: 350px;
    max-width: 100%;
  }
}
.subquestions{
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid $borderColor;
  margin-top: 15px;
  margin-bottom: 20px;
}
.subquestion{
  margin-top: 20px;
  &:first-child{
    margin-top: 0;
  }
}

@media only screen
and (max-width: 991px){
  .radioImage{
    width: 30%;
  }
}

@media only screen
and (max-width: 767px){
  .content{
    padding: 55px 30px 20px;
  }
}
@media only screen
and (max-width: 575px){
  .radioImage{
    width: 45%;
  }
  .question--radio3 .ant-radio-button-wrapper{
    width: 50% !important;
    &:last-child{
      width: 100% !important;
    }
  }
  .radioImage-label img, .radioImage-label svg{
    padding: 20px;
  }
  .radioImage:nth-child(4) .radioImage-label img, .radioImage:nth-child(4) .radioImage-label svg{
    padding: 10px;
  }
  .wrapper{
    margin-top: 0;
    margin-bottom: 0;
  }
  .header-logo{
    padding: 20px;
  }
  .header-img{
    height: 150px;
  }
  .content{
    padding: 30px 30px 20px;
  }
}